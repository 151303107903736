import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Header, Button } from "semantic-ui-react";
import Modal from "../components/Modal";
import styled from "styled-components";
import Layout from "../components/Layout";
import ToolkitForm, { IToolkitFormState } from "../components/ToolkitForm";
import { MainHeader } from "../helpers/styledComponents";
import { useUserState } from "../components/Layout";

const PageContainer = styled(Container)`
  .instructions{
    color: #FAB60B;
  }
`;

const SuccessMessageContainer = styled.div<{light?: boolean}>`
  text-align: center;
  .ui.header{
    color: ${props => props.light ? "#fff": "#FAB60B"};
    &.thankYou{
      font-size: 3em;
    }
    &.instruction{
      font-size: 1.5em;
      margin-bottom: 1em;
    }
    @media(min-width: 768px){
      &.thankYou{
        font-size: 5em;
      }
      &.instruction{
        font-size: 2.5em;
      } 
    }
  }
  .downloadBtn{
    color:  ${props => props.light ? "#FAB60B": "#fff"};
    background: ${props => props.light ? "#fff": "#FAB60B"};
  }
`;

const SuccessMessage = ({ light }: {light?: boolean})=>(
  <SuccessMessageContainer light={light}>
    <Header as="h1" className="thankYou"> THANK YOU </Header>
    <Header as="h2" className="instruction">
      DOWNLOAD <br />
      THE RESEARCH REPORT BELOW
    </Header>
    <Button 
      className="downloadBtn" 
      size="massive" 
      content="DOWNLOAD"
      href="/Full_Story_Research.pdf" target="_blank" />
  </SuccessMessageContainer>
)

const UserFormContainer : React.FC <{setOpenModal : (open: boolean) => void}> = ({setOpenModal} ) => {
  const user = useUserState();

  const handleSubscribe = (data: IToolkitFormState) => {   
    setOpenModal(true);
  }

  return (
    <>   
    {!user.email && (
      <>
        <ToolkitForm onSubmitHandler={handleSubscribe} />
        <Header as="h2" textAlign="center" className="instructions">
          CLICK SUBMIT TO DOWNLOAD <br /> THE RESEARCH REPORT
        </Header>
      </>
    )}

    {user.email && (
      <SuccessMessage />
    )}
    </>
  );
}


export default () => {
  const [openModal, setOpenModal] = useState(false);
  
  return (
    <>
      <Helmet>
        <title>Driving Box Office Performance through Authentically Inclusive Storytelling | Full Story Initiative </title>
        <meta name="title" content="Driving Box Office Performance through Authentically Inclusive Storytelling | Full Story Initiative" />
        <meta name="og:title" content="Driving Box Office Performance through Authentically Inclusive Storytelling | Full Story Initiative" />
        <meta name="twitter:title" content="Driving Box Office Performance through Authentically Inclusive Storytelling | Full Story Initiative" />
        <meta name="og:site_name" content="Driving Box Office Performance through Authentically Inclusive Storytelling | Full Story Initiative" />
      </Helmet> 
      <Layout>
        <PageContainer>
          <MainHeader>
            <Header className="h3 dark normal extraSpace">              
                Driving Box Office Performance<br />
                through<br /> 
                Authentically Inclusive Storytelling
            </Header>
            <Header className="compact toolkitHeader">
              RESEARCH
            </Header>
            <Header className="h3 dark">
                <p>Driving Box Office Performance through Authentically Inclusive Storytelling is a research report done in partnership with CAA, Full Story Initiative, and The Center for Scholars &amp; Storytellers at UCLA.</p>
                <p>The report builds on and expands previous research on AIR (Authentically Inclusive Representation) to further emphasize the need to improve both the quantity and quality of representation in filmmaking at all levels.</p>
                <p>The report findings add additional unique data to the growing body of evidence that studios leave money on the table when they don't invest in projects led by diverse creatives and executives.</p>
                <p>This research also provides actionable insights for those involved in every step of film creation &#8212; from pitch to promotion.</p>
            </Header>
          </MainHeader>
          <UserFormContainer setOpenModal={setOpenModal}  />
        </PageContainer>

        <Modal open={openModal} onClose={()=> setOpenModal(false)}>
          <SuccessMessage light />
        </Modal>

      </Layout>
    </>
  )
};
